export const state = {
    message: null,
    errors: null,
    color: 'success',
    flag: ""
}

export const getters = {
    message: state => state.message,
    errors: state => state.errors,
    color: state => state.color,
    flag: state => state.flag
}

export const mutations = {
    setMessage(state, message) {
        state.message = message;
    },
    setErrors(state, errors) {
        state.errors = errors
    },
    setColor(state, color) {
        state.color = color || 'success'
    },
    setFlag(state, flag) {
        state.flag = flag || ''
    },
    resetState(state) {
        state.message = null;
        state.errors = null;
        state.color = 'success';
        state.flag = '';
    }
}

export const actions = {
    setMessage({ commit }, message) {
        commit('setMessage', message)
    },
    setErrors({ commit }, errors) {
        commit('setErrors', errors)
    },
    setColor({ commit }, color) {
        commit('setColor', color)
    },
    setFlag({ commit }, flag) {
        commit('setFlag', flag)
    },
    setAlert({ commit }, data) {
        commit('setMessage', data.message || null)
        commit('setErrors', data.errors || null)
        commit('setColor', data.color || null)
        commit('setFlag', data.flag || null)
    },
    resetState({ commit }) {
        commit('resetState')
    }
}