const SERVER_URL = process.env.VUE_APP_API_URL;
const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY;
const _2020_EMPLOYEE_ERC_AMOUNT = 4000;
const _2021_EMPLOYEE_ERC_AMOUNT = 16800;

/*
(789) 123-4567
789.123.4567
+1 (789) 123-4567
+17891234567
789 123 4567
789-123-4567
 */
const PHONE_NUMBER_PATTERN = /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/

const ROLE = {
    ADMIN: "admin",
    USER: "USER"
}

const EMAIL_PATTERN = /^[^A-Z]*$/

export default {
    SERVER_URL,
    TOKEN_KEY,
    ROLE,
    _2020_EMPLOYEE_ERC_AMOUNT,
    _2021_EMPLOYEE_ERC_AMOUNT,
    PHONE_NUMBER_PATTERN,
    EMAIL_PATTERN
}
