import axios from "axios";
import Constants from "@/utils/constants";

const search = async (formdata, pageNum = 1, perPageCnt = 10) => {
    const {
        state,
        zip_code,
        business_type,
        company_name,
        jobsreported,
        ascending,
        sortColumn,
        borroweraddress,
        loannumber
    } = formdata;
    const skip = (pageNum - 1) * perPageCnt;
    // let query_params = `?state=${state}&skip=${skip}&limit=${perPageCnt}&zipcode=${zip_code}&company_name=${company_name}&business_type=${business_type}&jobsreported=${jobsreported}&ascending=${ascending}&sortColumn=${sortColumn}&loannumber=${loannumber}&borroweraddress=${borroweraddress}`;
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.defaults.headers.common['x-access-token'] = Constants.TOKEN_KEY
        const res = await axios.post(`${Constants.SERVER_URL}/api/search`, {
            state,
            zip_code,
            business_type,
            company_name,
            jobsreported,
            ascending,
            sortColumn,
            borroweraddress,
            loannumber,
            skip,
            limit: perPageCnt
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getLoansByName = async (borrowername, zipcode) => {
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/search/loans_by_borrowername`, {
            borrowername,
            zipcode
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getSimilarLoansByZipBusinessType = async (zip, type) => {
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/search/similar_loans`, {zip, type});
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const searchPPP = async (company_name) => {
    try {
        let params = new URLSearchParams();
        params.append("company_name", company_name)
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/pppsearch`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const searchIndustry = async (industry) => {
    try {
        let params = new URLSearchParams();
        params.append("industry", industry)
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/industrysearch`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const updateQuestion = async (submit, application_id, question) => {
    try {
        let params = new URLSearchParams();
        params.append("question", question)
        params.append("application_id", application_id)
        params.append("submit", submit)
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/updatequestion`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getQuestion = async (application_id) => {
    try {
        let params = new URLSearchParams();
        params.append("application_id", application_id);
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/getquestion`, params);
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
}

const postApplication = async (name, email, user_title, mobile_no, total_estimate, companies) => {
    try {
        let params = new URLSearchParams();
        params.append("person_name", name);
        params.append("person_email", email);
        params.append("user_title", user_title);
        params.append("mobile_no", mobile_no);
        params.append("companies", companies);
        params.append("total_estimate", total_estimate);
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/application`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}


const getApplications = async () => {
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/applications`);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getCompanyByAppID = async (application_id) => {
    let params = new URLSearchParams();
    params.append("application_id", application_id);
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/company_by_appid`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}


const saveGrossSales = async (appID, grossSales) => {
    let params = new URLSearchParams();
    params.append("appID", appID);
    params.append("gross_sales", JSON.stringify(grossSales));
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/save_gross_sales`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const saveCobraHealth = async (appID, health, cobra) => {
    let params = new URLSearchParams()
    params.append('appID', appID)
    params.append("cobra", JSON.stringify(cobra))
    params.append("health", JSON.stringify(health))

    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/save_cobra_health`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const savePayrolls = async (appID, payrolls, family_members) => {
    let params = new URLSearchParams();
    params.append('appID', appID);
    params.append('payrolls', JSON.stringify(payrolls));
    params.append('family_members', JSON.stringify(family_members));
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/save_payrolls`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

const saveFamilyMembers = async (appID, familyMembers) => {
    let params = new URLSearchParams();
    params.append('appID', appID);
    params.append('familyMembers', JSON.stringify(familyMembers));
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/save_family_members`, params);
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
}

export default {
    search,
    getLoansByName,
    getSimilarLoansByZipBusinessType,
    searchPPP,
    searchIndustry,
    updateQuestion,
    getQuestion,
    postApplication,
    getApplications,
    getCompanyByAppID,
    saveGrossSales,
    saveCobraHealth,
    savePayrolls,
    saveFamilyMembers
}