import PageNotFound from "../views/errors/404-cover.vue";
import store from "@/state/store";

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/auth/login'),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "applications"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('../views/pages/auth/signup'),
        meta: {
            title: "Signup",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "home"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/confirmation',
        name: 'confirmation',
        meta: {title: "Email Confirmation Page", authRequired: true},
        component: () => import('../views/pages/auth/confirmation'),
    },

    {
        path: '/new-application',
        name: 'new-application',
        meta: {title: "New Application", authRequired: true},
        component: () => import('../views/pages/new-application/new-application'),
    },

    {
        path: '/',
        name: 'applications',
        meta: {title: "Choose Application", authRequired: true},
        component: () => import('../views/pages/applications/applications'),
    },
    {
        path: '/application/:id',
        component: () => import('../views/pages/application/application.vue'),
        children: [
            {
                path: 'questionnaire',
                name: 'home',
                meta: {title: "Questionnaire", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/questionnarie/questionnarie'),
            },
            {
                path: 'documents',
                name: 'documents',
                meta: {title: "Document", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/documents/documents'),
            },
            {
                path: 'grosssales',
                name: 'grosssales',
                meta: {title: "grosssales", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/gross-sales/gross-sales'),
            },
            {
                path: 'healthexpenses',
                name: 'healthexpenses',
                meta: {title: "healthexpenses", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/health-expenses/health-expenses'),
            },
            {
                path: 'payroll',
                name: 'payroll',
                meta: {title: "payroll", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/payroll/payroll'),
            },
            {
                path: 'uploadtaxdocument',
                name: 'uploadtaxdocument',
                meta: {title: "uploadtaxdocument", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/uploadtaxdocument/uploadtaxdocument'),
            },

            {
                path: 'ownersfamilymembers',
                name: 'ownersfamilymembers',
                meta: {title: "ownersfamilymembers", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/ownersfamilymembers/ownersfamilymembers'),
            },
            {
                path: 'irsform2848',
                name: 'irsform2848',
                meta: {title: "irsform2848", authRequired: true},
                props: true,
                component: () => import('../views/pages/application/irsform2848/irsform2848'),
            },
        ]
    },
    {
      path: '/verify-email/token/:token',
      name: 'verify-email',
      meta: {title: 'Verify Email', authRequired: true},
      component: () => import('../views/pages/verify-email/verify-email.vue')
    },
    {
        path: '/invalid-key',
        name: 'invalid-key',
        meta: {title: "Invalid Key", authRequired: true},
        component: () => import('../views/pages/auth/InvalidActivationKey'),
    },
    {
        path: '/key-expired',
        name: 'key-expired',
        meta: {title: "Email Confirmation Page", authRequired: true},
        component: () => import('../views/pages/auth/key-expired'),
    },
    {
        path: '/invalid-expired-token',
        name: 'invalid-expired-token',
        meta: {title: "Invalid Token", authRequired: true},
        component: () => import('../views/pages/auth/invalid-or-expired-token'),
    },
    {
        path: '/reset_password',
        name: 'reset_password',
        meta: {title: "Reset Password", authRequired: false},
        component: () => import('../views/pages/auth/reset-password'),
    },

    {
        path: '/locked',
        name: 'locked',
        meta: {title: "Locked", authRequired: true},
        component: () => import('../views/pages/auth/locked'),
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        meta: {title: "Forgot Password", authRequired: false},
        component: () => import('../views/pages/auth/forgot-password'),
    },
    {
        path: '/registration',
        name: 'registration',
        meta: {title: "Registration", authRequired: false},
        component: () => import('../views/pages/auth/registration'),
    },
    {
        path: '/error500',
        name: '500',
        meta: {
            title: "Error 500", authRequired: false,
        },
        component: () => import('../views/errors/500')
    },
    {
        path: '/oauth/error',
        name: 'oauth',
        meta: {
            title: "Error Oauth", authRequired: false,
        },
        component: () => import('../views/errors/oauth-error')
    },
    {
        path: '/:catchAll(.*)*',
        name: '404-cover',
        meta: {
            title: "Error 404", authRequired: false,
        },
        component: PageNotFound
    },
];