import axios from "axios";
import Constants from "@/utils/constants";

const getUsers = async (pageNum, perPageCnt, formdata) => {
    const {search} = formdata;
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/getusers`, {
            pageNum,
            perPageCnt,
            search
        });
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

const getUserById = async (user_id) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.post(`${Constants.SERVER_URL}/api/getuser`, {
            user_id
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

const forgetPassword = async (email) => {
    return await  axios.post(`${Constants.SERVER_URL}/api/v1/auth/send_email`, {
        email,
    })
}

const resetPassword = async (new_password, token, uuid) => {
    return await  axios.post(`${Constants.SERVER_URL}/api/v1/auth/reset_password`, {
        new_password,
        token,
        uuid
    })
}

const resendVerifyEmail = async () => {
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/auth/confirm-email`);
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

const verifyEmail = async (token) => {
    let params = new URLSearchParams()
    params.append('token', token)
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/auth/verify-email`, params);
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

const reset = async (uuid, token) => {
    let params = new URLSearchParams()
    params.append('uuid', uuid)
    params.append('token', token)
    try {
        const res = await axios.post(`${Constants.SERVER_URL}/api/v1/auth/reset`, params);
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export default {
    getUsers,
    getUserById,
    forgetPassword,
    resetPassword,
    resendVerifyEmail,
    verifyEmail,
    reset
}