import Constants from "@/utils/constants";
import axios from "axios";


export const state = {
    currentUser: JSON.parse(localStorage.getItem('userdata')) ?? null,
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState('userdata', newValue);
    },
    LOG_OUT(state, newValue){
        state.currentUser = newValue;
        logOut();
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser;
    },
    currentUser: state => state.currentUser
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    async logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        return await  axios.post(`${Constants.SERVER_URL}/api/v1/auth/login`, {
            email: email,
            password: password,
        })
        .then((response) => {
            if (response.data.status === 'success'){
                const user = response.data.user;
                commit('SET_CURRENT_USER', user);
                return {user: user, status: "success", message: response.data.message};
            }else {
                return {user: null, status: "errors", message: response.data.message};
            }
        })
        .catch((errors) => {
            return {user: null, status: "errors", message: errors};
        })
    },

    // Logs out the current user.
    async logOut({ commit }) {
        commit('LOG_OUT', null);
        localStorage.removeItem('userdata');
        localStorage.removeItem('userid');
        // await  axios.get(`${Constants.SERVER_URL}/api/auth/logout`);
    },

    // register the user
    async register({ commit, dispatch, getters }, { email, firstname, lastname, phone_number, personal_address, password, confirm_password } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        return await  axios.post(`${Constants.SERVER_URL}/api/v1/auth/signup`, {
              email,
              firstname,
              lastname,
              phone_number,
              personal_address,
              password,
              confirm_password
        })
        .then((response) => {
            if (response.data.status === 'success'){
                const user = response.data.user;
                commit('SET_CURRENT_USER', user);
                return {user: user, status: "success", message: response.data.message};
            }else {
                return {user: null, status: "errors", message: response.data.message};
            }
        })
        .catch((errors) => {
            return {user: null, status: "errors", message: errors.message};
        })
    },



    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        // return getFirebaseBackend().forgetPassword(email).then((response) => {
        //     const message = response.data
        //     return message
        // });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({ commit, state }) {
        console.log(commit);
        if (!state.currentUser) return Promise.resolve(null)
        // const user = getFirebaseBackend().getAuthenticatedUser();
        //commit('SET_CURRENT_USER', user)
        //return user;
    },

    setCurrentUser({commit}, user) {
        commit('SET_CURRENT_USER', user)
    }
}

// ===
// Private helpers
// ===
function saveState(key, state) {
    localStorage.setItem(key, JSON.stringify(state))
}

function logOut(){
    localStorage.removeItem("redirect");
    localStorage.removeItem("userdata");
    localStorage.removeItem('jwt');
    localStorage.removeItem("userid");
}