import Constants from "./constants";

Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};


const usDollar = (amount) => {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return USDollar.format(amount);
}

const decimalDollar = (str_dollar) =>{
    return parseFloat(str_dollar).toFixed(2);
}

const tillDateFromDatetime = (datetime) => {
    let date = new Date(datetime);
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
}

const toDateString = (datetime) => {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(datetime)
    return [pad(d.getMonth()+1), pad(d.getDate()), d.getFullYear()].join('/')
}

const addCommaToNumber = (num) => {
    return num.toLocaleString();
}

const toLowerCase = (letters) => {
    return letters.toLowerCase();
}

const subString = (str, len) => {
    if (str.length <= len)
        return `${str.substring(0, len)}`;
    else
        return `${str.substring(0, len)} ...`;
}

const getOnlyPhoneNumbers = (phone) => {
    let numbersOnly = phone.replace(/[^\d]/g, "");
    // Check if the number starts with '+1' and remove it
    if (numbersOnly.startsWith("1")) {
        numbersOnly = numbersOnly.slice(1);
    }
    return numbersOnly;
}

const presentPhoneNumber = (phone) => {
    return `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6)}`;
}



/*
(789) 123-4567
789.123.4567
+1 (789) 123-4567
+17891234567
789 123 4567
789-123-4567
 */
const validatePhoneNumber = (number) => {
    const pattern = Constants.PHONE_NUMBER_PATTERN;
    return pattern.test(number.trim());
}

export default {
    tillDateFromDatetime,
    toDateString,
    addCommaToNumber,
    toLowerCase,
    subString,
    usDollar,
    getOnlyPhoneNumbers,
    presentPhoneNumber,
    decimalDollar,
    validatePhoneNumber
}